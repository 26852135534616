import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://3c43580c9c3c42938dc05dcec65cbb3d@o4505352740798464.ingest.sentry.io/4505363230818304',
  environment: document.querySelector('meta[name="environment"]')?.content ?? 'unknown',
  ignoreErrors: [/^Can't find variable: \$$/, /^Syntax error, unrecognized expression: \[data-"Times New Roman"-slider\]$/],
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [/^https:\/\/.*\.hirescore\.com/]
    }),
    new Sentry.Replay()
  ],
  tracesSampleRate: 1.0,
  release: document.querySelector('meta[name="release"]')?.content ?? 'unknown',
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.5
})
